/* App.css */
body {
  display: flex;
  flex-direction: column;
}

nav {
  display: flex;
  justify-content: space-between;
  background-color: #025E73;
  padding: 5px 25px;
}
.slick-next, .slick-prev {
  color: rgb(4, 4, 4);
}
.slick-next::before , .slick-prev::before {
 color: rgb(4, 4, 4);
}
.slick-next::after , .slick-prev::after  {
  color: rgb(4, 4, 4);
}

.horaactual {
  text-align: right;
  margin-left: auto;
  margin-right: 20px;
  font-size: 18px;
  color: rgb(26, 26, 161);
  font-weight: 500;
  margin-right: 50px;
}

.headertabla {
  padding: 5px 50px;
  display: flex;
  justify-content: space-around;
  height: 100px;
  margin-top: 25px;
  width: 80%;
  margin: auto;
}

.sloganerlan {
  width: 20%;
  height: 100px;
}

a {
  text-decoration: none;
}

a:hover {
  color: white;
}

.atras {
  font-size: 18px;
  color: #fff;
  margin: 10px 30px;
}

.salir {
  font-size: 18px;
  color: #fff;
  margin: 10px 30px;
}

.strongqr {
  color: rgb(26, 26, 161);
  font-size: 20px;
}

.control {
  font-size: 18px;
  font-weight: bold;
  color: rgb(26, 26, 161);
  margin-bottom: 30px;
  text-align: start;
  width: 100%;
}

.tituloregistro {
  font-size: 25px;
  font-weight: bold;
  color: rgb(37, 134, 13);
  margin-bottom: 30px;
  text-align: center;
}

.app-container {
  font-family: 'Arial', sans-serif;
  padding: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.datos-usuario {
  text-align: right;
  margin-left: auto;
  margin-right: 50px;
  text-transform: none;
  font-size: 20px;
  color: rgb(26, 26, 161);
  font-weight: bold;
}

.datos-usuarioizq {
  text-align: left;
  margin-left: auto;
  margin-right: 50px;
  text-transform: none;
  font-size: 20px;
  color: #011F26;
  font-weight: bold;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mapita {
  position: inherit;
}

label {
  margin-right: 10px;
  font-size: 18px;
}

.error-message {
  margin-bottom: 15px;
  text-align: center;
  background-color: rgb(227, 227, 94);
  padding: 10px;
  border-radius: 5px;
  width: 20%;
}

.botonsalida {
  color: white;
  font-size: 20px;
}

.obtener-datos-btn {
  background-color: rgb(22, 73, 200);
  color: #fff;
  font-weight: bold;
  padding: 8px 50px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 5px 3px 5px #4f7093;
  margin-top: 20px;

}

.obtener-datos-btnqr {
  background-color: rgb(22, 73, 200);
  color: #fff;
  font-weight: bold;
  padding: 8px 50px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 5px 3px 5px #4f7093;
  margin-top: 20px;
  margin-left: 21%;
}
.obtener-datos-btn:active {
  background-color: rgb(165, 39, 39);
  box-shadow: 5px 3px 5px #49aa7d;
}

.titulodatos {
  color: white;
  padding: 10px;
  margin-left: 30px;
  font-size: 25px;
  text-align: center;
}
.xcerrar {
  background-color: rgb(222, 45, 45);
  color: white;
  width: 6%;
  padding: 6px;
  font-weight: bold;
}
.funcionseleccionada {
  background-color: #007bff;
  text-align: center;
  padding: 10px;
  color: white;
  width: 100%;
  font-weight: bold;
}
.funcionnoseleccionada {
  background-color: red;
  text-align: center;
  padding: 10px;
  color: white;
  width: 100%;
  font-weight: bold;
}
.custom-modal .botoncerrar {
  background-color: rgb(194, 15, 15);
  width: 40%;
  padding: 3px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin: 5px;

}
.custom-modal .botonentrada {
  background-color: rgb(61, 180, 34);
  width: 40%;
  padding: 3px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin: 5px;

}
.custom-modal .botonentrada:hover {
  background-color: rgb(61, 180, 34);
  padding: 3px;
  color: white;
  font-size: 20px;
  cursor: pointer;
}
.custom-modal .botonsalida {
  background-color: rgb(53, 53, 166);
  width: 40%;
  padding: 3px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin: 5px;
}
.custom-modal .botonsalida:hover {
  background-color: rgb(53, 53, 166);
  padding: 3px;
  color: white;
  font-size: 20px;
  cursor: pointer;
}
.custom-modal button .registrarplaca {
  background-color: rgb(107, 194, 15);
  width: 40%;
  padding: 3px;
  color: white;
  font-size: 20px;
  box-shadow: 0 0 5px 2px rgb(107, 194, 15);
  cursor: pointer;
}
.custom-modal button .registrarplaca:hover {
  background-color: rgb(170, 213, 49);
  padding: 3px;
  color: white;
  font-size: 20px;
  box-shadow: 0 0 5px 2px rgb(170, 213, 49);
  cursor: pointer;
}
.fechayhora {
  font-size: 18px;
}
.datos {
  padding: 15px;
}
@media screen and (max-width:480px) {
  .global{
    margin-top: 30%;
  }
  body {
    align-items: center;
    display: flex;
    height: 100vh;
    flex-direction: column;
  }
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 5px;
  }
  .horaactual {
    font-size: 10px !important;
    margin-right: 20px;
    margin-top: 5px;
    color: #707171 !important;
  }
  .logoerlan {
    width: 40% !important;
    height: 50px !important;
    object-fit: cover;
  }
  .atras {
    font-size: 12px !important;
    color: #fff;
    margin: 10px 10px !important;
  }
  .salir {
    font-size: 12px !important;
    color: #fff;
    margin: 10px 10px !important;
  }
  .tituloregistro {
    font-size: 14px;
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .inputboton {
    display: flex;
    padding: 4px;
    font-size: 10px;
    width: 88% !important;
    margin-top: 20px;
  }
  .botonesabajo {
    display: flex;
    padding: 4px;
    font-size: 10px;
    width: 88% !important;
    margin-top: 0 !important;
  }
  #placaInput {
    margin: 0 !important;
    border-radius: 2px !important;
    width: 50% !important;
    text-align: center;
    border: 1px solid #0091ff;
    font-size: 13px !important;
    height: 40px;

  }
  .botonregistrar {
    width: 50% !important;
    font-size: 13px !important;
    border-radius: 2px !important;
    height: 40px;

  }
  .botonlistarnombres {
    width: 50% !important;
    font-size: 13px !important;
    border-radius: 2px !important;
    height: 40px;

  }
  .verregistrosgeneral {
    width: 50% !important;
    font-size: 8px;
    border-radius: 2px !important;
    margin: 0 !important;
    
  }
  .dropdown {
    width: 50% !important;
    font-size: 13px !important;
    border-radius: 2px !important;
  }
  .dropdown button {
    width: 100% !important;
    font-size: 12px !important;
    border-radius: 2px !important;
    height: 40px;

  }
  .registrosusuario {
    max-width: 90%;
  }
  .btnmisregistros {
    max-width: 90%;
  }
  .divbotonesbajocamara {
    width:92% !important;
    flex-direction: column;
  }
  .cargarcamara {
    font-size: 12px !important;
    width: 100% !important;
    border-radius: 2px;

  }
  .botones-Camara{
    display: flex;
    gap: 5px;
    width: 100%;
  }
  .botoncambiarcamara {
    display: initial !important;
    font-size: 12px !important;
    width: 100% !important;
    border-radius: 2px;

  }
  .botonlimpiarfoto {
    font-size: 15px !important;
    width: 100% !important;
    border-radius: 2px;

  }

  .botontomarfoto {
    font-size: 15px !important;
    width: 100% !important;
    border-radius: 2px;

  }

  .botonsubirfoto {
    font-size: 15px !important;
    width: 100% !important;
    border-radius: 2px;

  }
  .cardDatos{
    width: 90% !important;
    max-width: 100%;
  }
  .datosestaticos{
    width: 100%;
  } 
  .datosestaticosnombres{
    width: 100%;
    flex-direction: column !important;
    margin-bottom: 10px;
  }
  .datosestaticosnombres p{
    margin-bottom: 0 !important;
    gap: 5px;
  }
  .font-responsive{
    font-size: 12px;
  }
  .pagination li a{
    font-size: 10px !important;
  }
  .botonesrecursos {
    width: 100% !important;
    flex-direction: column;
    margin: 0 !important;
    margin-bottom: 1rem !important;
  }
  .botonesrecursos Button{
    width: 100% !important;
    font-size: 12px !important;
  }
  .btnseleccionar{
    width: 100% !important;
    font-size: 12px !important;

  }
  .titulomodalregistro {
    font-size: 14px;
    text-align: center;
  }

  .radiobutonstr label {
    font-size: 12px !important;
  }
  .brdatos{
    display: none;
  }
  .alertafuncionselec {
    font-size: 13px !important;
  }

  .cardprincipal {
    width: 100% !important;
  }

  .inputbuscador {
    font-size: 10px !important;
    max-width: 100% !important;
  }

  .camaraconcainer {
    width: 90% !important;
  }

  .botonbuscador {
    font-size: 10px !important;
  }

  nav {
    padding: 5px 0;
  }

  .control {
    padding: 10px;
  }

  .app-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
  }

  .botonps {
    font-size: 9px;
  }

  .gestionarpagoimg {
    height: 50px !important;
  }

  #verasistenciabtn button {
    font-size: 9px !important;
  }

  .datos-usuario {
    text-align: right;
    margin-left: auto;
    margin-right: 20px;
    font-size: 10px !important;
    color: rgb(26, 26, 161);
    font-weight: bold;
  }
  .titulo-RegistroDoc{
    display: none;
  }
  .datos-usuarioizq {
    font-size: 9px !important;
    width: 100%;
    color: rgb(68, 68, 69);
    font-weight: bold;
    margin: 10px 0 0 5px;
    margin-top: 5px !important;

  }

  .divcodigos {
    display: flex;
    padding: 5px;
  }

  .nombresapellidosmodal {
    font-size: 12px !important;
  }
  .size12RV{
    font-size: 12px !important;

  }
  .funcionmodal {
    font-size: 12px !important;
    padding: 5px;
    z-index: 1052;
  }
  .selectPerfil{
    font-size: 12px !important;
    padding: 5px;
    z-index: 1000;
  }
  .SelectFuncion{
    font-size: 12px !important;
    padding: 5px;
    z-index: 1003;
  }
 
  .SelectTR{
    font-size: 12px !important;
    padding: 5px;
    z-index: 1002;
  }
  .SelectRecurso{
    font-size: 12px !important;
    padding: 5px;
  }
  .SelectRadio{
    font-size: 12px !important;
    padding: 5px;
    z-index: 1002;
  }
  .SelectRecursoB{
    font-size: 12px !important;
    padding: 5px;
    z-index: 1001;
  }
  .SelectPF{
    font-size: 12px !important;
    padding: 5px;
    z-index: 1000;
  }
  .tiporegistromodal {
    font-size: 12px !important;
  }

  label {
    margin-right: 0;
    font-size: 13px;
  }

  .obtener-datos-btn {
    border: none;
    cursor: pointer;
    border-radius: 5px;
    width: 60%;
  }

  .titulodatos {
    padding: 10px;
    margin-left: 0;
    font-size: 15px;
  }

  .custom-modal .botonentrada {
    font-size: 12px;
  }

  .custom-modal .botonsalida {
    font-size: 12px;
  }

  .custom-modal .botoncerrar {
    font-size: 12px;
  }

  .fechayhora {
    font-size: 14px;
  }

  .datos {
    display: flex;
    flex-direction: column;
  }

  .botonesregistros button {
    font-size: 12px;
    margin-left: 2px;
    margin-right: 2px;
  }

  .botonesmapas {
    display: flex;
    flex-direction: column;
  }

  .botonmapageneral {
    width: 100%;
  }

  .botonmapageneral button {
    width: 95%;
    font-size: 12px;
  }

  .botonessuccess {
    justify-content: center;
  }

  .botonessuccess button {
    font-size: 12px;
  }

  .botoncambiocontra {
    font-size: 10px;
    width: 5px;
  }

  .error-message {
    width: 90%;
  }

  .xcerrar {
    width: 15%;
    padding: 5px;
    font-size: 12px;
  }

  .selectintervalo {
    font-size: 10px !important;
    margin: 0 !important;
    width: 100% !important;
  }

  .cambiarintervalo {
    gap: 10px;
  }

  .input-container button {
    width: 30% !important;
    font-size: 12px;

  }
  .bitacoradocumento{
    width: 100% !important;
  }
  .bitacoracategoria{
    width: 100% !important;
  }
}

.botonesmodal {
  display: flex;
  justify-content: right;
  width: 100%;
  gap: 15px;
}

.seleccimagen {
  background-color: rgb(42, 203, 42);
  border: none;
  width: 170px;
}

.textplaca {
  font-size: 20px;
}